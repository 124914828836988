<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <div @click="returnToPreviousPage" style="cursor: pointer;">
            <icon icon="#cx-hea1-arrow-left" />
          </div>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.edit-order") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="selectedOrderItems.length"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="serviceOrder">
      <li
        class="clebex-item-section-item full-right-underline"
        style="cursor: pointer;"
      >
        <div
          class="clebex-item-content-wrapper"
          @click="showInfoBox = !showInfoBox"
          style="cursor: pointer;"
        >
          <div
            class="clebex-section-input"
            style="cursor: pointer;"
            @click="showInfoBox = !showInfoBox"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("services.services.order-number") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ serviceOrder.data.order_id }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)',
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.address }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="requestBy">{{
              displayLabelName("services.services.request-by")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="requestBy"
              v-model="serviceOrder.data.request_by"
              disabled="true"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.services.created-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.created_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.service-items.number-of-items")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="dateTime"
              v-model="serviceItemsLength"
              disabled="true"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox && serviceOrder.data.deleted_at"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.deleted-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.deleted_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
        style="cursor: pointer;"
      >
        <router-link
          :to="{
            name: 'r_services-edit-order-history',
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input" style="cursor: pointer;">
            <label class="clebex-section-input-label" style="cursor: pointer;">
              {{ displayLabelName("services.services.history") }}
            </label>
          </div>
          <span class="follow-up-icons" style="cursor: pointer;">
            <span class="follow-up-icon-item" style="cursor: pointer;">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
                style="cursor: pointer;"
              />
            </span>
          </span>
        </router-link>
      </li>
    </ul>
    <VeeForm
      :key="formKey"
      ref="editServiceOrderForm"
      @submit="submitForm"
      class="form inline-input edit-form"
      style="height: auto;"
      novalidate
    >
      <ul
        class="clebex-item-section pill"
        v-if="listOfServiceOrderItems && listOfServiceOrderItems.length"
      >
        <!-- Date from - date to -->
        <span class="delivery-period">{{
          displayLabelName("services.services.date-time")
        }}</span>
        <br /><br />
        <li
          class="clebex-item-section-item"
          style="margin-bottom: 0px; cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li
          class="clebex-item-section-item time"
          style="cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="showDateTimePicker"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>

      <!-- Selected service items -->
      <span
        class="order-level"
        style="margin-left: 15px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
        >{{ displayLabelName("services.services.selected-items") }}</span
      >
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 10px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="selectedOrderItem in selectedOrderItems"
          :key="selectedOrderItem.id"
        >
          <div style="display: flex; flex-direction: row;">
            <button class="clebex-item-content-wrapper">
              <input
                type="checkbox"
                class="service-order-checkbox"
                :id="`service-item${selectedOrderItem.id}`"
                name="serviceitem"
                :selected="checkedOrderItems.includes[selectedOrderItem.id]"
                :value="selectedOrderItem.id"
                @change="checkSelectedOrderItem(selectedOrderItem)"
              />
              <label :for="`service-item${selectedOrderItem.id}`"></label>
              <span
                class="label"
                @click="openItemDetails(selectedOrderItem.id)"
              >
                <span class="highlight">{{ selectedOrderItem.name }}</span
                >&nbsp;
                <span v-if="selectedOrderItem.quantity" class="highlight"
                  >({{ selectedOrderItem.quantity }})</span
                >
              </span>
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  v-if="selectedOrderItem.id == $route.params.order_item_id"
                  @click="selectOrderItem(selectedOrderItem)"
                >
                  <icon icon="#cx-app1-checkmark"></icon>
                </span>
              </span>
            </button>
            <span
              class="follow-up-icons service-item-info"
              style="cursor: pointer;"
              @click="openServiceItemInfo(selectedOrderItem.id)"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </div>
        </li>
      </ul>
      <!-- Service items -->

      <span class="order-level" style="margin-top: 20px; margin-left: 15px;">{{
        displayLabelName("services.service-items.service-items")
      }}</span>
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 0px;"
        v-for="(serviceOrderType, index) in listOfServiceOrderItems"
        :key="index"
      >
        <div
          class="service-order-type"
          @click="showHideType(serviceOrderType.id)"
        >
          <span class="order-level"
            >{{ serviceOrderType.name }} ({{
              selectedOrderItems.filter(
                (el) => el.service_type_id == serviceOrderType.id
              ).length
            }}/{{ serviceOrderType.items.length }})</span
          ><icon
            class="order-type-icon"
            icon="#cx-hea1-arrow-left"
            style="[
              showItemType(serviceOrderType.id)
                ? 'transform: rotate(90deg)'
                : 'transform: rotate(-90deg)'
            ]"
          />
        </div>
        <div v-if="showItemType(serviceOrderType.id)" style="margin-top: 7px;">
          <li
            class="clebex-item-section-item"
            v-for="serviceOrderItem in serviceOrderType.items"
            :key="serviceOrderItem.id"
          >
            <div
              style="display: flex; flex-direction: row;"
              v-if="
                !selectedOrderItems.find((el) => el.id == serviceOrderItem.id)
              "
            >
              <button
                class="clebex-item-content-wrapper"
                @click="
                  selectOrderItem(serviceOrderItem);
                  openItemDetails(serviceOrderItem.id);
                  setDates();
                "
              >
                <span class="label">
                  <span class="highlight">{{ serviceOrderItem.name }}</span>
                </span>
                <span class="follow-up-icons">
                  <span
                    class="follow-up-icon-item"
                    v-if="checked(serviceOrderItem.id)"
                  >
                    <icon icon="#cx-app1-checkmark"></icon>
                  </span>
                </span>
              </button>
              <span
                class="follow-up-icons service-item-info"
                style="cursor: pointer;"
                @click="openServiceItemInfo(serviceOrderItem.id)"
              >
                <span class="follow-up-icon-item">
                  <icon icon="#cx-app1-information"></icon>
                </span>
              </span>
            </div>
          </li>
        </div>
      </ul>
      <!-- {{ saveInitial }}
      <br /><br />
      {{ selectedOrderItems }}
      <br /><br />
      {{ serviceOrderFrom }}
      <br /><br />
      {{ serviceOrderTo }}
      <br /><br />
      {{ checkedOrderItems }} -->
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <!-- <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("resources.resource-types.select") }}
            </button>
          </li> -->
          <li class="action" v-if="checkedOrderItems.length">
            <button
              class="action-btn"
              :disabled="processing"
              @click="showDeleteModal = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                checkedOrderItems.length +
                "/" +
                selectedOrderItems.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <!-- Modal -->
    <screen-modal
      class="confirm-modal"
      type="success"
      :hideTimer="true"
      :confirm-action="saveDataFromConfirm"
      :confirm-button-label="displayLabelName('global.buttons.yes')"
      :cancelButtonLabel="displayLabelName('global.buttons.no')"
      :show="showConfirmation"
      @close="returnToPreviousPageConfirmed"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("services.services.would-you-like-to-save-the-data")
        }}
      </h3>
    </screen-modal>
    <screen-modal
      class="confirm-modal"
      type="success"
      :hideTimer="false"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('resources.resource-types.delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('resources.resource-types.delete-cancel-action')
      "
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.services.delete-service-order-items") }}
      </h3>
      <p class="text">
        {{
          displayLabelName("services.services.delete-service-order-items-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";
import { defineAsyncComponent } from "vue";
import { formatDate } from "@/services/helpers";

export default {
  name: "EditOrder",
  data() {
    return {
      formKey: 0,
      searchQuery: "",
      submitFormState: false,
      edit: false,
      showDateTimePicker: false,
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      listOfShowHideTypes: null,
      showInfoBox: false,
      saveInitial: false,
      showConfirmation: false,
      showDeleteModal: false,
      checkedOrderItems: [],
    };
  },
  created() {
    this.setServiceOrder(null);
    this.selectOrderItem([]);
    this.getServiceOrder(this.$route.params.service_order_id);
    this.setDates();
  },
  watch: {
    routeParam() {
      this.edit = false;
      if (this.routeParam) {
        this.setServiceOrder(null);
        this.selectOrderItem([]);
        this.getServiceOrder(this.$route.params.service_order_id);
        this.showInfoBox = false;
        this.formKey++;
      }
    },
    serviceOrder() {
      if (this.serviceOrder && this.serviceOrder.data) {
        this.getServiceOrderItems(this.serviceOrder.data.resource_id);
      }
    },
    serviceOrderItems() {
      this.listOfShowHideTypes = this.serviceOrderItems.data.map((item) => ({
        id: item.id,
        show: this.$route.params.order_type_id == item.id ? true : false,
      }));

      for (var i = 0; i < this.serviceOrderItems.data.length; i++) {
        var items = this.serviceOrderItems.data[i].items;
        for (var k = 0; k < items.length; k++) {
          for (
            var j = 0;
            j < this.serviceOrder.data.service_items.length;
            j++
          ) {
            if (items[k].id == this.serviceOrder.data.service_items[j].id) {
              this.selectOrderItem(items[k]);
              this.selectedOrderItems.forEach((element) => {
                if (element.id === items[k].id) {
                  element.quantity = this.serviceOrder.data.service_items[
                    j
                  ].quantity;
                  element.note = this.serviceOrder.data.service_items[j].note;
                  element.cost_code = this.serviceOrder.data.service_items[
                    j
                  ].cost_code;
                  element.requested_time_from = this.serviceOrder.data.service_items[
                    j
                  ].requested_time_from;
                  element.requested_time_to = this.serviceOrder.data.service_items[
                    j
                  ].requested_time_to;
                }
              });
            }
          }
        }
      }
    },
    serviceResource() {
      this.getResourceServices(this.serviceResource);
      if (this.serviceOrder && this.serviceOrder.data) {
        this.selectedDateFrom = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("yyyy-dd-MM");
        this.selectedDateTo = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("yyyy-dd-MM");
        this.selectedDateFromParsed = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("yyyy-MM-dd");
        this.selectedDateToParsed = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("yyyy-MM-dd");
        this.selectedHourFrom = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("HH:mm");
        this.selectedHourTo = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("HH:mm");
      }
    },
    selectedDateFrom() {
      this.setDates();
    },
    selectedDateTo() {
      this.setDates();
    },
    selectedHourFrom() {
      this.setDates();
    },
    selectedHourTo() {
      this.setDates();
    },
    // selectedOrderItems: {
    //   handler(oldVal, newVal) {
    //     // if (this.saveInitial == false) {
    //     //   this.saveInitial = oldVal.length == 0 ? false : true;
    //     // }
    //     this.canSave = [];
    //     newVal.forEach((item) => {
    //       if (
    //         item.cost_code_required &&
    //         (item.cost_code == null || item.cost_code == "")
    //       ) {
    //         this.canSave.push(
    //           item.name +
    //             " - " +
    //             this.displayLabelName("services.services.cost-code")
    //         );
    //       }
    //     });
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("resource", ["resourceServices", "selectedResourceServices"]),
    ...mapState("service", [
      "serviceOrder",
      "serviceOrderItems",
      "selectedOrderItems",
      "serviceOrderFrom",
      "serviceOrderTo",
    ]),
    routeParam() {
      return this.$route.params.service_order_id;
    },
    serviceResource() {
      if (this.serviceOrder && this.serviceOrder.data) {
        return this.serviceOrder.data.resource_id;
      }
      return null;
    },
    serviceItemsLength() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        return this.serviceOrder.data.service_items.length;
      }

      return 0;
    },
    listOfServiceOrderItems() {
      if (this.serviceOrderItems && this.serviceOrderItems.data) {
        return this.serviceOrderItems.data;
      }
      return null;
    },
  },
  methods: {
    ...mapActions("resource", [
      "getResourceServices",
      "setResourceServices",
      "selectResourceService",
      "setSelectedResourceServices",
    ]),
    ...mapActions("service", [
      "setServiceResource",
      "getServiceOrder",
      "setServiceOrder",
      "getServiceOrders",
      "getServiceOrderItems",
      "selectOrderItem",
      "setServiceOrders",
      "setPage",
      "setTotalPages",
      "setServiceOrderFrom",
      "setServiceOrderTo",
    ]),
    startDelete() {
      let items = this.selectedOrderItems.filter(
        (item) => !this.checkedOrderItems.includes(item.id)
      );

      this.selectOrderItem(items);
      this.submitFormState = true;
      this.initSubmit();

      if (this.selectedOrderItems.length == 0) {
        this.$router.push({
          name: this.backLinkName,
          params: this.$route.params,
        });
      }
    },
    checkSelectedOrderItem(selectedOrderItem) {
      if (this.checkedOrderItems.includes(selectedOrderItem.id)) {
        this.checkedOrderItems.splice(
          this.checkedOrderItems.indexOf(selectedOrderItem.id),
          1
        );
      } else {
        this.checkedOrderItems.push(selectedOrderItem.id);
      }
    },
    saveDataFromConfirm() {
      this.submitFormState = true;
      this.initSubmit();
    },
    returnToPreviousPage() {
      // this.saveInitial = false;
      if (this.selectedOrderItems.length > 0) {
        this.showConfirmation = true;
      } else {
        this.$router.push({
          name: this.backLinkName,
          params: this.$route.params,
        });
      }
    },
    returnToPreviousPageConfirmed() {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params,
      });
    },
    setDates() {
      this.setServiceOrderFrom(
        `${DateTime.fromISO(
          this.selectedDateFromParsed
        ).toISODate()}T${DateTime.fromISO(this.selectedHourFrom).toFormat(
          "HH:mm"
        )}:00`
      );
      this.setServiceOrderTo(
        `${DateTime.fromISO(
          this.selectedDateToParsed
        ).toISODate()}T${DateTime.fromISO(this.selectedHourTo).toFormat(
          "HH:mm"
        )}:00`
      );

      if (this.selectedOrderItems) {
        this.selectedOrderItems.forEach((item) => {
          let x = item;
          if (item.requested_time_from == null) {
            x.requested_time_from = this.serviceOrderFrom;
          }
          if (x.requested_time_to == null) {
            x.requested_time_to = this.serviceOrderTo;
          }
        });
      }
    },
    openItemDetails(id) {
      this.$router.push({
        name: "r_services-resources-edit-order-item-details",
        params: {
          order_item_id: id,
        },
      });
    },
    showItemType(id) {
      if (this.listOfShowHideTypes) {
        return this.listOfShowHideTypes.find((item) => item.id == id).show;
      }
      return false;
    },
    showHideType(id) {
      var showHide = this.listOfShowHideTypes.find((item) => item.id == id)
        .show;
      this.listOfShowHideTypes.find((item) => item.id == id).show = !showHide;
    },
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");

      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
      this.saveInitial = true;
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];

      if (this.selectedHourFrom && this.selectedHourTo) {
        this.saveInitial = true;
      } else {
        this.saveInitial = false;
      }
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    dateFormat(date) {
      return formatDate(
        date,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    },
    openServiceItemInfo(serviceItemId) {
      this.$router.push({
        name: "r_services-edit-order-item-info",
        params: {
          order_item_id: serviceItemId,
        },
      });
    },
    checked(id) {
      return (
        this.selectedOrderItems &&
        this.selectedOrderItems.length &&
        !!this.selectedOrderItems.find((item) => item.id === id)
      );
    },
    formValues() {
      let response = [];

      for (var i = 0; i < this.selectedOrderItems.length; i++) {
        let object = {
          quantity: null,
          note: "",
          requested_time_from: null,
          requested_time_to: null,
          cost_code: "",
          service_status: "ORDER",
        };
        object.id = this.selectedOrderItems[i].id;

        object.quantity = this.selectedOrderItems[i].quantity;
        object.note = this.selectedOrderItems[i].note;
        object.requested_time_from = this.selectedOrderItems[
          i
        ].requested_time_from;
        object.requested_time_to = this.selectedOrderItems[i].requested_time_to;
        object.cost_code = this.selectedOrderItems[i].cost_code;

        response.push(object);
      }
      return response;
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values && this.submitFormState) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.submitFormState = false;

      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      values = {
        resource_id: this.serviceResource,
        service_status_id: 1,
        service_items: this.formValues(),
        delivery_start: dateTimeFrom,
        delivery_end: dateTimeTo,
      };
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.serviceOrders}/${this.$route.params.service_order_id}`,
          values
        )
        .then(() => {
          localStorage.removeItem("costCode");
          this.setPage(null);
          this.setTotalPages(null);
          this.getServiceOrders();
          this.saveInitial = false;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
  },
};
</script>
<style>
.order-wrapper {
  border: 1px solid lightgray !important;
  border-top: 0px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 3px;
}
.order-item {
  border-radius: 0px !important;
  background: transparent !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
.order-label {
  padding-left: 0px !important;
}
.order-field {
  background: white !important;
  padding: 5px !important;
  height: 2rem !important;
}
.service-item-info {
  background-color: white;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}
.delivery-period {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
.order-type-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  transform: rotate(-90deg);
  margin-right: 15px;
}
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0px !important;
  margin-left: 7px;
}
.service-order-type {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 0.8rem;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  align-items: center;
}
.change-quantity {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.service-order-checkbox {
  display: none;
}

/* Create a custom checkbox */
.service-order-checkbox + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  color: #999999;
  font-size: 14px;
}

/* Create the round checkbox */
.service-order-checkbox + label::before {
  content: "";
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #a7a5a5; /* Border color */
  border-radius: 50%; /* Make it round */
  background: #fff; /* Background color */
  transition: background 0.3s;
}

/* Add a checkmark inside the round checkbox */
.service-order-checkbox:checked + label::before {
  background: #a7a5a5; /* Checked background color */
}

.service-order-checkbox:checked + label::after {
  content: "";
  position: absolute;
  left: 13px;
  top: 30%;
  transform: translateY(-60%) rotate(45deg);
  width: 4px;
  height: 8px;
  border: solid #faf6f6;
  border-width: 0 2px 2px 0;
}
</style>
